import React, { useState } from 'react';
import { ToastContainer,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const [data, setdata] = useState({ name: '', email: '', number: '', description: '' });

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setdata({ ...data, [name]: value });
  }
  // const notify = (message) => toast(`${message}`, {
  //   position: "top-center",
  //   autoClose: 1000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "colored",
  // });

  //   const saveDataInDB=async(event)=>{
  //     event.preventDefault();
  //     try {
  //       const output=await Api_Service.saveContactUsUser(data);
  //       if(output.data.error==false){
  //         console.log(data);
  //         setdata({name:'',email:'',date:'',description:'',number:''})
  //         notify('Thanks Your Response Save Success');
  //       }else{
  //         notify('Server is busy now try again');
  //         console.log(data);
  //       }
  //     } catch (error) {
  //       notify('Network Error Check Your Internet Connection');
  //     }
  //   }

  return (
    <>
      <>
        <div className="container-fluid p-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h3 className="text-center fw-bold">Contact Us</h3>
              <p className="text-center">We are just a step away from you!</p>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-xl-10">

              <p className="text-center">
                Have any query about our service, need support, or anything else? Feel
                free to get in touch with us. Drop your details and we will get back
                to you.
              </p>
              <form onSubmit={''}>
                <div className="row">
                  <div className="col-xl-6 mt-3">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      name='name'
                      required
                      autoComplete='off'
                      value={data.name}
                      onChange={formHandler}
                      className="form-control"
                      placeholder="What is Your Name"
                    />
                  </div>
                  <div className="col-xl-6 mt-3">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      name='email'
                      required
                      autoComplete='off'
                      value={data.email}
                      onChange={formHandler}
                      className="form-control"
                      placeholder="What is Your Email"
                    />
                  </div>
                  <div className="col-xl-6 mt-3">
                    <label htmlFor="">Number</label>
                    <input
                      maxLength={10}
                      minLength={10}
                      type="tel"
                      pattern="[6789][0-9]{9}"
                      title='please enter valid phone number'
                      name='number'
                      required
                      autoComplete='off'
                      value={data.number}
                      onChange={formHandler}
                      className="form-control"
                      placeholder="What is Your Phone Number"
                    />
                  </div>

                  <div className="col-xl-12 mt-3">
                    <div htmlFor="">Description</div>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      autoComplete='off'
                      required
                      value={data.description}
                      name="description"
                      onChange={formHandler}
                    />
                  </div>

                  <div className="col-xl-4 mt-3">
                    <button className="btn btnstyle"   >Send Message</button>
                  </div>
                </div>
              </form>
              <div className="row text-center mt-5">
                <div className="col-xl-4">
                  <div className="rounded shadow-sm p-2">
                    <h3>
                      <i className="bi bi-telephone-fill text-success" /> Phone
                    </h3>
                    <a href="tel:+919694881008">
                      <p className="text-secondary">+919694881008</p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="rounded shadow-sm p-2">
                    <h3>
                      <i className="bi bi-envelope-fill text-success" /> Email
                    </h3>
                    <a href="mailto:myEdubuddies@gmail.com">
                      <p className="text-secondary">myEdubuddies@gmail.com</p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="rounded shadow-sm p-2">
                    <h3>
                      <i className="bi bi-meta text-success" /> Social Media
                    </h3>
                    <div>
                      <a href="https://www.facebook.com/profile.php?id=61557410617838&mibextid=qi2Omg&rdid=fUDJ46rFp22qH2v3">
                        <i className="bi bi-facebook fs-3 mx-1 text-primary" />
                      </a>
                      <a href="https://wa.me/9694881008">
                        <i className="bi bi-whatsapp fs-3 mx-1 text-success" />
                      </a>
                      <a href="https://www.instagram.com/myedubuddies_official">
                        <i className="bi bi-instagram fs-3 mx-1 text-danger" />
                      </a>
                      <a href="https://twitter.com/myedubuddies?t=m4MbkMIzrsQ3nlCmhmiY9g&s=08">
                        <i className="bi bi-twitter fs-3 mx-1 text-info" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>

    </>
  )
}

export default ContactUs