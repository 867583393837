import React from 'react';

function AboutUs() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1 className="text-center">About Us</h1>
          <p>Welcome to MyeduBuddies, your ultimate destination for navigating the intricate world of career paths and educational pursuits. At MyeduBuddies, we understand the pivotal role that choosing the right career path plays in shaping your future. Whether you're a student exploring various options or an individual seeking to switch careers, we're here to accompany you on your journey towards success.</p>

          <h2>Our Mission</h2>
          <p>Our mission at MyeduBuddies is to empower students and professionals alike with the knowledge and guidance necessary to make informed decisions about their educational and career trajectories. We believe that every individual deserves access to personalized support and expert advice to unlock their full potential.</p>

          <h2>Expert Counselor Guidance</h2>
          <p>Navigating the vast array of career options and educational pathways can be daunting. That's why we offer personalized counseling sessions with expert advisors who are well-versed in various industries and educational fields. Our counselors are dedicated to understanding your unique strengths, interests, and aspirations to help you chart a course that aligns with your goals.</p>

          <h2>Comprehensive Services</h2>
          <p>From the initial stages of researching potential career paths to securing placements after graduation, MyeduBuddies is your one-stop solution. We provide comprehensive services that encompass every step of the journey, including assistance with course selection, application processes, and interview preparations. Our goal is to streamline the entire process and alleviate any stress or uncertainty you may encounter along the way.</p>

          <h2>Why Choose MyeduBuddies?</h2>
          <ul>
            <li>Personalized Approach: We believe in tailor-made solutions that cater to your individual needs and preferences.</li>
            <li>Extensive Expertise: Our team of experienced counselors and advisors brings a wealth of knowledge across various industries and academic disciplines.</li>
            <li>End-to-End Support: From initial consultation to post-graduation placements, we're committed to supporting you at every stage of your educational and career endeavors.</li>
            <li>Trusted Guidance: Thousands of students and professionals have entrusted us with their aspirations, benefiting from our personalized guidance and support.</li>
          </ul>

          <h2>Join Us on Your Journey</h2>
          <p>Embark on your journey towards a fulfilling and successful career with MyeduBuddies by your side. Whether you're exploring career options, seeking educational advice, or navigating the complexities of the job market, we're here to empower you every step of the way. Let's shape your future together.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
