import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {createBrowserRouter,RouterProvider} from 'react-router-dom';
import Main from './pages/Main';
import ErrorPage from './pages/ErrorPage';
import ContactUs from './pages/companies_pages/ContactUs';
import AboutUs from './pages/companies_pages/AboutUs';
import PrivacyPolicy from './pages/companies_pages/PrivacyPolicy';
import StudentForm from './pages/companies_pages/StudentInqueryForm'

const routes=createBrowserRouter([
  {
    path:'/',
    element:<App/>,
    errorElement:<ErrorPage/>,
    children:[
      {
        index:true,
        element:<Main/>
      },
      {
        path:'student-inquery',
        element:<StudentForm />
      },
      {
        path:'contact',
        element:<ContactUs/>
      },
      {
        path:'about',
        element:<AboutUs/>
      },
      {
        path:'privacy',
        element:<PrivacyPolicy/>
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={routes}/>
  </React.StrictMode>,
)
