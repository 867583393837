import React from 'react';
import Slider from 'react-slick';
import '../design/Card.css'; // Import your CSS file for styling
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CounsellorCard = ({ name, about, image, education }) => {
  return (
    <div className='counsellor-card'>
      <button className="mail">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-mail"
        >
          <rect width="20" height="16" x="2" y="4" rx="2"></rect>
          <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
        </svg>
      </button>
      <div className="profile-pic">
        <img src={image} alt={`Profile picture of ${name}`} />
      </div>
      <div className="bottom">
        <div className="content">
          <span className="name">{name}</span>
          <span className="about-me">{about}
            <ul>
              {education.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul></span>
          {/* <span className="about-me">{education}</span> */}
        </div>
        <div className="bottom-bottom">
          <span style={{ color: 'aliceblue' }}>{name}</span>
          {/* <div className="social-links-container">5+</div> */}
          <button className="button">Connect with Me</button>
        </div>
      </div>
    </div>
  );
};

const CounsellersCards = () => {
  const counselorsData = [
    { id: 1, name: 'Vertika Sarkari', about: 'Certified as a Super Career Counsellor, I am Vertika Sarkari weaponise several technology-enabled information to deliver professional assistance to you. A life coach and an author both in one. With my intensive knowledge, I can help manage your stress, and career decisions and take your life to whole new levels of success.', education: ['  Master of Commerce (M.Com) in Business Administration from Mohanlal Sukhadia University, Udaipur.', 'I am an MBA in Self Management & Crisis Management.', 'Green Belt Certification in Global Career Counselling from the University of California, Los Angeles (UCLA Extension).', ' Gold Certification from Univariety, Singapore.'], image: 'VertikaSarkari.jpeg' },
    { id: 2, name: 'Meenakshi Vashistha', about: 'Throughout my professional career as a Career Strategist, Child Counselor, and Parent Coach, I Have had the privilege of guiding individuals and families through various stages of personal and professional development. As a Career Strategist, I Have provided personalized guidance to individuals seeking clarity and direction in their career paths, offering support through assessments, career awareness  and parental engagement strategies. ', education: [' B.com (Rajasthan University)', 'Child Counselor (NHCA, Singapore)', 'Parent Coach (DEEP Parenting)', 'Career Strategist (International Institute of Career Coaching)'], image: 'MeenakshiVashistha.jpeg' },
    {
      id: 3, name: ' Bindu Menon', about: ' Bindu Menon, an experienced professional with over 8 years of corporate experience,  provides extensive expertise and insight to her role as a career counsellor at Menon Global   Careers.With a passion for guiding individuals towards fulfilling and rewarding career paths,   Bindu has dedicated the past 3+ years to helping students navigate the complexities of  career choices.',education:['Bachelor’s of Science with major in Chemistry GNIIT from NIIT','Certified Global Career Counsellor from UCLA- Ext','Green Belt Certification in Career Counselling from Univariety','Certificate of specialisation in Student Profile Building Beyond Academics from University','Certificate of Specialisation in Premium Overseas Admission'], image: 'BinduMenon.png' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of counselor cards to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="container" >
      <section className="">
        <div className="container mt-5" >
          <div className="row justify-content-between py-3">
            <div className="col-lg-7 contentecenter">
              <div>
                <h3 className="fw-bold heading-title-h">
                  Find the Best <span className="color fs-2"> Counsellors </span> at
                  the right time
                </h3>
                <p className='paragraph-text'>
                  Embark on a journey of discovery with our comprehensive counseling services designed to support students in charting their academic and career paths, including opportunities for studying abroad.
                </p>
                <p className='paragraph-text'>
                  Our team of experienced counselors is dedicated to providing personalized guidance tailored to your unique goals and aspirations, whether it's pursuing courses abroad or exploring further education options. We understand the complexities of studying abroad and offer specialized assistance to help you navigate the application process, understand visa requirements, and adapt to new cultures and environments.
                </p>
                <p className='paragraph-text'>
                  Additionally, we provide insights into the further scope of placements in your field of study, offering guidance on internships, job opportunities, and career prospects both locally and internationally. From creating compelling resumes to honing interview skills, we offer practical assistance to enhance your professional profile and maximize your potential.
                </p>
                <p className='paragraph-text'>
                  Access our extensive resources and embark on a transformative journey towards academic and career fulfillment.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="counsellerService.jpeg"
                className="img-fluid rounded-4 "
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="row justify-content-center">
        <div className="col-lg-12 mb-4">
          <h3 className="fw-bold heading-title-sections">My Edu-Counselors</h3>
        </div>
      </div>
    
      <Slider {...settings}>
        {counselorsData.map(counselor => (
          <div key={counselor.id} className="counselor-slide">
            <CounsellorCard name={counselor.name} about={counselor.about} education={counselor.education} image={counselor.image} />
          </div>
        ))}
      </Slider>

    </div>
  );
};

export default CounsellersCards;
