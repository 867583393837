import React from "react";
import Card from "../components/Card";
import ServiceCard from "../components/ServiceCard";
import CounsellersCards from "../components/CounsellersCards";

const Main = () => {


  return (
    <>
      <div className="container-fluid" >
        <section className="hero" id="home"  >
          <div className="text-center mt-4"></div>
          <div className="container py-3"  >
            <div className="row">
              <div className="col-lg-6 text-start contentecenter">
                <div className="animationhero  ">
                  <h1 className="fw-bold primary-heading heading-title-sections" style={{ fontFamily: 'Figtree, Figtree' }}>
                    New Year New
                    Launching offer Flat
                    40% off on Buddy Deals
                    <br />
                  </h1>
                    <span className="display-6 paragraph-text" style={{ fontSize: "26px" }}>
                      We Will Help You <span className="color">To Make Your Best Career Path! </span>
                    </span>
                  <div className="my-2">
                    <div className="mt-3 row justify-content-center">
                      <div className="col-lg-10 col-12">
                        <a href="/student-inquery" className="swipe">Start Your Journey! <span className="containerbtn"><svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path></svg></span> </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1">
              </div>
              <div className="col-lg-4">
                <div>
                  <img
                    src="MyEduBuddies_home.jpeg"
                    className="img-fluid  rounded-3 shadow-sm imageMediaClass"
                    alt="myedubuddies"
                  />
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>

        <section id="whycounselling" style={{ marginTop: '40px' }}>
          <div className="row OurServiceMainHeadingBackground p-5 ">
            <div className="col-lg-12 OurServiceMainHeadingBackgroundImage ">
              <h2 className="heading-title-s" style={{ textAlign: 'center' }}><span>Why Counselling?</span></h2>
              <p style={{ textAlign: 'center' }}>
                Education counselling is like having a helpful guide on your journey through school and beyond. It's important because it helps you figure out what you want to do in the future and how to reach
                there.              </p>
              <p style={{ textAlign: 'center' }}>
                Education counselling isn't just about getting good grades or going to a fancy college. It's about finding what makes you happy and successful in the long run.
                <p> Whether you want to be a doctor, an artist, or an engineer, education counselling can help you find your path and reach your goals.</p>
              </p>
            </div>
          </div>
          <div className="row justify-content-center " >

            <div className="col-lg-6">
              <div className="BackGroundColorChange BackGroundImage row"  >
                <div className="HomeServiceContaient">
                  <div className="HomeServiceIcon"><i className="bi bi-house-fill fs-1" /></div>
                  <div className="mt-3 mb-4"><h4>Reasons for student
                    career failure :</h4></div>
                  <div className="HomeServiceContent">
                    <span>
                      <li>Students are confused to choose
                        right career path.</li>
                      <li>Parental lack of knowledge about
                        current career opportunities.</li>
                      <li>
                        Influence of mob mentality,
                        divert students towards wrong
                        career path.

                      </li>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="BackGroundColorChange BackGroundImageMiddle row"  >
                <div className="HomeServiceContaient">
                  <div className="HomeServiceIcon"><i className="bi bi-laptop color fs-1 " /></div>
                  <div className="mt-3 mb-4"><h4>Benefits of Authentic
                    Counselling :</h4></div>
                  <div className="HomeServiceContent" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    <span>
                      <li>Psychometric tests reveal inner
                        abilities of a student.
                      </li>
                      <li>Discussions with certified
                        experienced counselors offer
                        suitable opportunities</li>
                      <li>combined reports of authentic
                        counselor provides specific career
                        path.</li>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* </div> */}
        </section>


        <section className="myeduvision" id="myeduvision">
          <div className="container mt-5 my-1">
            <div className="row justify-content-center py-3">
              <div className="col-lg-5">
                <img
                  src="WhyCounselling.jpeg"
                  className="img-fluid rounded-5"
                  alt=""
                />
              </div>
              <div className="col-lg-6 contentecenter">
                <div>
                  <h3 className="text-secondary fw-bold heading-title-sections mt-4">
                    My Edu-Vision
                  </h3>
                  <p className='paragraph-text'>
                    My Edu Buddies aims to offer authentic, step-by-step
                    counselling led by certified experienced counselors in India,
                    steering clear of misleading forced sales tactics often
                    encountered by students and parents.
                  </p>
                  <p className='paragraph-text'>
                    Our Vision is to Empowering Futures Through Authentic Career Counselling
                    At MyEduBuddies we envision a world where every 12th pass out and college pass out student is equipped with the guidance and support they need to unlock their full potential and achieve their dreams.
                    Your Ambition is Our Vision, and we are committed to providing authentic career counselling that transcends borders.
                  </p>
                  <p className='paragraph-text'>
                    Whether you aspire to pursue further studies within the country or embark on an exciting journey of studying abroad, our dedicated team of counsellors is here to accompany you every step of the way. With personalized guidance tailored to your individual aspirations and circumstances, we help you navigate the complexities of educational and career choices with confidence and clarity.
                  </p>
                  <p className='paragraph-text'>
                    Our mission is to empower you to make informed decisions about your future studies and career, ensuring that you embark on a path that aligns with your passions, interests, and long-term goals. Through authentic counselling, we aim to inspire and support you as you explore endless possibilities, both within the country and beyond.
                  </p>
                  <p className='paragraph-text'>Join us on this transformative journey, where your ambition becomes our shared vision for a brighter and more prosperous future. Together, let's build the foundation for your success and unlock the doors to boundless opportunities, wherever your aspirations may lead you. Your journey begins here.</p>
                </div>
              </div>


              <div className="row py-5 justify-content-center" >

                <h3 className="fw-bold heading-title-h text-center">
                  Step-by-step Process to Get Your Solution
                </h3>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ width: "100%", overflow: "hidden", height: "auto" }}>
                    <video className="video-fluid" autoPlay loop muted style={{ width: "100%", height: "auto" }}>
                      <source src="Step-by-Step.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="shadow p-3 rounded-2 bordertop mt-2">
                    <i className="bi bi-file-earmark-medical color fs-1"></i>
                    <p className='paragraph-text'>Start your journey with us, Registered now. </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="shadow p-3 rounded-2 bordertop mt-2">
                    <i className="bi bi-book-half fs-1"></i>
                    <p className='paragraph-text'>Give Psychometric Test to see what is best for you.</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow p-3 rounded-2 bordertop mt-2">
                    <i className="bi bi-person-workspace fs-1"></i>
                    <p>
                      As per the test result will get the one-on-one Counselor Discussion and Career Report for your future reference.
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="shadow p-3 rounded-2 bordertop mt-2">
                    <i className="bi bi-hand-thumbs-up-fill fs-1"></i>
                    <p className='paragraph-text'>We'll provide Admission Suport for best career path.</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="paragraph-text shadow p-3 rounded-2 bordertop mt-2">
                    <div>
                      <i className="bi bi-mortarboard-fill fs-1"></i>
                    </div>
                    <p className='paragraph-text'>We'll help you for Future Training & Placements</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>



        <section className="myeduallies" id="myeduallies" >
          <Card />
        </section>

        <section>
          <div className="container-fluid mt-5 trustSectionBg">
            <div className="">
              <div className="row">
                <div className="col-md-3 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Authentic Counselling</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Certified Psychometric Test</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Institution Options</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">End to End Admission Support</p>
                </div>
                <div className="col-md-3 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Training & Placement Support</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="myeducounselors mt-5 mb-4" id="myeducounselors" >
          <CounsellersCards />
        </section>

        <section>
          <div className="container-fluid mt-5 SectionBg ">
            <div className="">
              <div className="row">
                <div className="col-md-3 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Authentic Counselling</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Certified Psychometric Test</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Institution Options</p>
                </div>
                <div className="col-md-2 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">End to End Admission Support</p>
                </div>
                <div className="col-md-3 text-white content-center eachmetric">
                  <i className="bi bi-check-circle-fill" style={{ color: '#008000' }} />
                  <p className="metric-name">Training & Placement Support</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="myedudeals mt-5" id="myedudeals" >
          <ServiceCard />
        </section>

        <section className="mystucorner" id="mystucorner">
          {/* Your testimonials section content */}
        </section>
      </div>
    </>

  );
};

export default Main;
