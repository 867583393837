import React, { useState } from 'react';


const StudentForm = () => {

    return (
        <div className='container'>
            <iframe
                title="Student Inquiry Form"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdHZD_druEQ2TZZ-NAdTr9eC-WhABUtSp7SMfGK72OO0tNiug/viewform"
                width="100%"
                height="1900"
                // frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                scrolling='no'
                
            >
                Loading…
            </iframe>
        </div>
    );
};

export default StudentForm;
