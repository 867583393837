import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
    return (
        <footer className="bg-light py-5">
            <div className="container">
                <div className="row mb-5">
                <div className="col-lg-3">
                        <div className="logo-container">
                            <img src="MyEduBuddies_logo.png" className="img-fluid logo" alt="MyEduBuddies Logo" />
                        </div>
                        <p className="footer-description">
                            MyEduBuddies.com is an online platform dedicated to education counseling. With a focus on guiding students through their educational journey, MyEduBuddies provides valuable resources, personalized advice, and support to help individuals make informed decisions about their academic and career paths.
                        </p>
                    </div>
                    <div className="col-lg-3">
                        <h4 className="fw-bold">Services</h4>
                        <p><a href="/" className="link">Authentic Counselling</a></p>
                        <p><a href="#whycounselling" className="link">Certified Psychometric Test</a></p>
                        <p><a href="#myeduvision" className="link">Institution Options</a></p>
                        <p><a href="#myeduvision" className="link">End to End Admission Support</a></p>
                        <p><a href="#myeduvision" className="link">Training & Placement Support</a></p>
                    </div>
                    <div className="col-lg-3">
                        <h4 className="fw-bold">Company</h4>
                        <p><Link className="link" to="/about">About Us</Link></p>
                        <p><Link className="link" to="/contact">Contact Us</Link></p>
                        <p><Link className="link" to="/privacy">Privacy Policy</Link></p>
                    </div>
                    <div className="col-lg-3">
                        <h4 className="fw-bold">Connect With Us</h4>
                        <div className="social-icons">
                                <p><a className="link" href="https://www.facebook.com/profile.php?id=61557410617838&mibextid=qi2Omg&rdid=fUDJ46rFp22qH2v3" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} className="facebook-icon" /> Facebook</a></p>
                                <p><a className="link" href="https://www.instagram.com/myedubuddies_official" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="instagram-icon" /> Instagram</a></p>
                                <p><a className="link" href="https://twitter.com/myedubuddies?t=m4MbkMIzrsQ3nlCmhmiY9g&s=08" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} className="twitter-icon" /> Twitter</a></p>
                                <p><a className="link" href="https://wa.me/9694881008" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" /> WhatsApp</a></p>
                                <p><a className="link" href="https://www.linkedin.com/company/myedubuddies-official/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="linkedIn-icon" /> Linkedin</a></p>
                                <p><a className="link" href="https://www.youtube.com/@myedubuddies_official" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} className="youtube-icon" /> YouTube</a></p>
                            </div>
                        </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <Link to="/">
                            <img src="MyEdubuddies_logo.png" className="img-fluid resized-image mb-5" alt="My Edu Buddies" />
                        </Link>
                    </div>
                </div>
                </div>

            </div>
            <div className="container-fluid bg-dark text-light py-3">
                <div className="container">
                    <p className="text-center mb-0">
                        All © copyright reserved MyEduBuddies | Design and Developed by Yash Dadhich
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
