import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../design/Header.css';

const Header = () => {
    const [isloggedin, setisloggedin] = useState(false);

    useEffect(() => {
        if (!sessionStorage.getItem('token')) {
            setisloggedin(false);
        } else {
            setisloggedin(true);
        }
    }, [isloggedin]);

    return (
        <>
            <nav className="navbar navbar-expand-lg headerBg">

                <div className="container">
                    <div>
                        <Link className="navbar-brand" to="/">
                            <img src="MyEduBuddies_logo.png" className="img-fluid resized-image" alt="" />
                        </Link>
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse main-navigation" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="/">
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#whycounselling">
                                    Why Counselling?
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#myeduvision">
                                    My Edu-Vision
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#myeducounselors">
                                    My Edu-Counselors
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#myedudeals">
                                    My Edu-Deals
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#myeduallies">
                                    My Edu-Allies
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#mystucorner">
                                    My Stu-Corner
                                </a>
                            </li>
                        </ul>
                        <div className='d-flex'>
                            <button>
                                <a href="/contact" className="btn2"><span className="spn2">Contact Us!</span></a>
                            </button>
                            <a href="/student-inquery" className="cta"> 
                                <span>Start Your Journey</span>
                                <svg width="15px" height="10px" viewBox="0 0 13 10">
                                    <path d="M1,5 L11,5"></path>
                                    <polyline points="8 1 12 5 8 9"></polyline>
                                </svg>
                            </a>

                        </div>

                    </div>
                </div>
            </nav>

            <div className="container mt-5 position-fixed d-none" style={{ zIndex: 999 }} id="databutton">
                <div className="row justify-content-center">
                    <div className="col-md-4 col-10 rounded shadow bg-white p-3">
                        <div className="modal-header d-flex">
                            <button type="button" className="btn-close submitbutton" />
                        </div>
                        <form action="">
                            <div className="py-2">
                                <input type="email" className="form-control" placeholder="What is Your Name" />
                            </div>
                            <div className="py-2">
                                <input type="number" className="form-control" placeholder="What is Your number" />
                            </div>
                            <div className="py-2">
                                <input type="text" className="form-control" placeholder="What is Your location" />
                            </div>
                            <div className="d-grid">
                                <button className="btnstyleoutline" type="submit">
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
