import React from 'react';

const Card = () => {
    return (
        <div className="container">
               <div className="row justify-content-center">
                <div className="col-lg-12 text-center mb-4">
                    <h3 className='text-secondary fw-bold heading-title-sections '>My Edu-Allies</h3>
                </div>
            </div>
            <div className="row py-5 justify-content-center">
                <div className="col-lg-2">
                    <div className="PatnerImageContainer">
                        <div className="PatnerImageMain">
                            <img decoding="async" className="teammember-image" src="Sunstone-Eduversity.jpg" alt="Sunstone Eduversity" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="PatnerImageContainer">
                        <div className="PatnerImageMain">
                            <img decoding="async" className="teammember-image" src="applyBoard.png" alt="ApplyBoard" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="PatnerImageContainer">
                        <div className="PatnerImageMain">
                            <img decoding="async" className="teammember-image" style={{height:'150px'}} src="upgrad.jpg" alt="Upgrade" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="PatnerImageContainer">
                        <div className="PatnerImageMain">
                            <img decoding="async" className="teammember-image" src="manipal-University.png" alt="Manipal University" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="PatnerImageContainer">
                        <div className="PatnerImageMain">
                            <img decoding="async" className="teammember-image" src="collegedekho_logo.jpg" alt="CollegeDekho Logo" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <ul className="list">
                        <li><h3 className='heading-title-h'>200+ Universities across pan India.</h3></li>
                        <li><h3 className='heading-title-h'> 30+ different UG/PG Courses.</h3></li>
                        <li><h3 className='heading-title-h'>1500+ Abroad Universities in 8+ Countries including MBBS admission.</h3></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Card;
