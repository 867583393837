import React from 'react'
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
    const error = useRouteError();
  console.error(error);
  return (
   <>
     <div className='text-center mt-5'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>You Are Lost Go Back. 🔙</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
   </>
  )
}

export default ErrorPage