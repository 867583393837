import React from 'react';
// import '../styles/PrivacyPolicy.css'; // Import your CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">

      <div className="col-md-8 offset-md-2">
      <h1 className="text-center">Privacy Policy</h1>
      <div>
      <p>Welcome to MyEduBuddies.com! This privacy policy outlines how we collect, use, and protect your personal information when you use our website.</p>
      
      <h2>Information We Collect</h2>
      <p>We collect personal information such as your name, email address, and other contact details when you register for our services or contact our counselors.</p>
      
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect to provide you with educational resources, career counseling services, and to communicate with you about our offerings.</p>
      
      <h2>Protecting Your Information</h2>
      <p>We take appropriate measures to protect your personal information from unauthorized access or disclosure. We do not sell or share your information with third parties without your consent.</p>
      
      <h2>Updates to This Policy</h2>
      <p>We may update this privacy policy from time to time. Any changes will be posted on this page.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us at myEdubuddies@gmail.com.</p>
    </div>
      </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
